import * as React from "react"
import { Container } from "react-bootstrap"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Disclaimer" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>
        Disclaimer
        </h1>
       
      </Container>
    </div>
    <Container className="singleCol two-rem-padding">

      <p>
        <em>
          Disclaimer: This information is made available by IDSS (the "Company"), and is intended for educational purposes only, and it is not legal advice. No action should be taken in reliance on any information in or on this site without verification with legal counsel, after review of the facts and current law, that the action to be taken is appropriate under the circumstance. Except as expressly provided to the contrary in writing by the Company, the materials contained on this site are provided on an "as-is" basis without warranties of any kind, either express or implied. Company disclaims all other warranties, express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement as to the information, content and materials on and in the site. Company does not represent or warrant that materials on and in the site are accurate, complete, reliable, current or error-free.
        </em>
      </p>
    </Container>
  </Layout>
)

export default IndexPage
